import { IGraph } from "./IGraph";
import { useEffect, useRef, useState } from "react";

export const useGraph = (props: IGraph) => {
  const graphWidth = 900;
  const graphHeight = 600;
  const xAxisPointsLength = props.investmentDuration + 1;
  const [xAxisPoints, setXAxisPoints] = useState<number[]>([]);
  const [yAxisPoints, setYAxisPoints] = useState<number[]>([]);

  const biggestValue = (): number => {
    const value = yAxisPoints[yAxisPoints.length - 1] * 1.1;
    return value;
  };

  const calculateMainLinePosition = graphHeight - 100 - ((graphHeight - 100) / biggestValue() || 0) * props.investmentAmount;
  const profitPosition = graphHeight - 100 - ((graphHeight - 100) / biggestValue() || 0) * props.profit;

  const drawYAxis = () => {
    const points = [];
    for (let i = 0; i <= xAxisPointsLength; i++) {
      points.push(i);
    }
    setXAxisPoints(points);
  };

  const getYAxisPoints = (): number[] => {
    if (props.isCzech) {
         switch (true) {
           case props.profit > 1300000:
             return [800000, 1600000, 2400000, 3200000];
              case props.profit > 640000:
                return [320000, 640000, 980000, 1300000];
               case props.profit > 320000:
                 return [160000, 320000, 480000, 640000];
                 case props.profit > 2600000:
                 return [180000, 360000, 540000, 720000];
                case props.profit > 160000:
                return [110000, 220000, 330000, 440000];
                case props.profit > 80000:
                 return [65000, 130000, 195000, 260000];
                case props.profit > 40000:
                 return [35000, 70000, 105000, 140000];
                case props.profit > 20000:
            return [18000, 36000, 54000, 72000];
          case props.profit > 12000:
                return [10000, 20000, 30000, 40000];
          default:
            return [5000, 10000, 15000, 20000];
      }
    } else {
      switch (true) {
        case props.profit > 120000:
          return [50000, 100000, 150000, 200000];
        case props.profit > 100000:
          return [30000, 60000, 90000, 120000];
        case props.profit > 80000:
          return [25000, 50000, 75000, 100000];
        case props.profit > 40000:
          return [20000, 40000, 60000, 80000];
        case props.profit > 18000:
          return [10000, 20000, 30000, 40000];
        case props.profit > 12000:
          return [6000, 12000, 18000, 24000];
        case props.profit > 8000:
          return [6000, 12000, 18000, 24000];
        case props.profit > 4000:
          return [4000, 8000, 12000, 16000];
        case props.profit > 3000:
          return [2000, 4000, 6000, 8000];
        case props.profit > 2000:
          return [1500,3000, 4500, 6000];
        default:
          return [2000, 4000, 6000, 8000];
      }
    }
  };

  const investmentDurationAxisAngle = () => {
    switch (true) {
      case props.investmentDuration > 15:
        return 7;
      case props.investmentDuration > 10:
        return 10;
      case props.investmentDuration > 5:
        return 15;
      case props.investmentDuration > 2:
        return 25;
      default:
        return 50;
    }
  };

  const [animationFinished, setAnimationFinished] = useState(false);

  const animElement = useRef<SVGAnimateMotionElement>(null);

  const handleAnimationEnd = () => {
    setAnimationFinished(true);
  };

  useEffect(() => {
    setYAxisPoints(getYAxisPoints);
  }, [props.profit]);

  useEffect(() => {
    drawYAxis();
  }, [xAxisPointsLength]);

  //add event listeners
  useEffect(() => {
    const animateMotion = animElement.current;
    if (animateMotion) {
      animateMotion.addEventListener("endEvent", handleAnimationEnd);
    }
    return () => {
      if (animateMotion) {
        animateMotion.removeEventListener("endEvent", handleAnimationEnd);
      }
    };
  }, []);

  return {
    graphWidth,
    graphHeight,
    xAxisPoints,
    yAxisPoints,
    biggestValue,
    xAxisPointsLength,
    calculateMainLinePosition,
    profitPosition,
    investmentDurationAxisAngle,
    animationFinished,
    animElement,
  };
};
