import { IGraph } from "./IGraph";
import { useGraph } from "./useGraph";
import { SvgGraphDefs } from "./SvgGraphDefs";
import { CurrencyWrapper } from "../../utils/currencyWrapper";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { yearTranslate } from "../../utils/yearTranslate";

export const Graph = (props: IGraph) => {
  const { t } = useTranslation();
  const { graphHeight, graphWidth, xAxisPoints, yAxisPoints, biggestValue, xAxisPointsLength, calculateMainLinePosition, profitPosition } =
    useGraph(props);

  const formattedProfit = props.formatter.format(props.profit);
  const bezierLineRef = useRef<SVGPathElement>(null);
  const highlightedtYearRef = useRef<SVGTextElement>(null);
  const [dotPosition, setDotPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    setTimeout(() => {
      const line = bezierLineRef.current!;
      const highlightedYear = highlightedtYearRef.current;
      const totalLength = Math.round(line.getTotalLength());
      if (highlightedYear) {
        // ToDo: Vic performance friendly reseni?...
        for (let i = 0; i <= totalLength; i++) {
          const xAxisPoint = Math.round(highlightedYear.getBBox().x);
          const currentCoords = line.getPointAtLength(i);
          if (Math.round(currentCoords.x) === xAxisPoint) {
            setDotPosition(currentCoords);
          }
        }
      }
    }, 1);
  }, [props.profit]);

  return (
    <svg viewBox={`0 0 ${graphWidth + 15} ${graphHeight - 50}`} className={"graph-content"}>
      <SvgGraphDefs />
      <g>
        {yAxisPoints.map((point, i) => {
          const calculateAnchorPoint = graphHeight - 100 - ((graphHeight - 100) / biggestValue()) * point;
          return (
            <React.Fragment key={i}>
              <line
                x1={0}
                x2={graphWidth}
                y1={calculateAnchorPoint}
                y2={calculateAnchorPoint + 1}
                stroke={"url(#linear-gradient-stroke)"}
              />
              <text textAnchor={"end"} x={graphWidth} y={calculateAnchorPoint + 20} className={"graph-content__label"}>
                {props.formatter.format(point)}
              </text>
            </React.Fragment>
          );
        })}
      </g>

      {/*Main line*/}
      <line x1={0} x2={graphWidth} y1={calculateMainLinePosition} y2={calculateMainLinePosition} className={"graph-content__mainLine"} />
      {/*Bezzier line */}
      <path
        ref={bezierLineRef}
        d={`
          M 0 ${calculateMainLinePosition}
          C 0 ${calculateMainLinePosition},
          ${(graphWidth / 3) * 2} ${profitPosition + (calculateMainLinePosition - profitPosition)},
          ${graphWidth + 2} ${profitPosition}
        `}
        fill={"transparent"}
        stroke={"url(#linear-gradient-bezier)"}
        strokeWidth={3}
      />
      {/*Bezzier fill*/}
      <path
        d={`
          M 0 ${calculateMainLinePosition}
          C 0 ${calculateMainLinePosition},
          ${(graphWidth / 3) * 2} ${profitPosition + (calculateMainLinePosition - profitPosition)},
          ${graphWidth} ${profitPosition}
          T ${graphWidth} ${graphHeight - 100}
          C ${graphWidth} ${graphHeight - 100},
          0 ${graphHeight - 100},
          0 ${graphHeight - 100}
        `}
        fill={"url(#linear-gradient)"}
        fillOpacity={0.2}
      />
      <g>
        <text className={"graph-content__tooltip-yield"} x={graphWidth - 380} y={graphHeight - 480} fill={"white"}>
            {t("calculator.yieldTitle", { vynos: Math.floor((parseFloat(props.yield) || 0) * 10000) / 100 })}
        </text>
        <text className={"graph-content__tooltip-price"} x={graphWidth - 380} y={graphHeight - 430} fill={"white"}>
          <CurrencyWrapper currencyString={formattedProfit} />
        </text>
      </g>
      <circle r={5} fill={"#6babdf"} cx={dotPosition.x} cy={dotPosition.y} />
      <g>
        <line x1={0} x2={graphWidth} y1={graphHeight - 99} y2={graphHeight - 100} stroke={"url(#linear-gradient-stroke)"} />
        {xAxisPoints.map((point, i) => (
          <text
            key={point}
            y={graphHeight - 70}
            x={((graphWidth - 70) / xAxisPointsLength) * point + 21 + (i === 0 ? -18 : 0)}
            className={`graph-content__label${point === props.investmentDuration ? "-current" : ""}`}
            ref={i === xAxisPointsLength - 1 ? highlightedtYearRef : null}
          >
            {i === 0 ? (
              t("calculator.today", "dnes")
            ) : (
              <>
                {point} {i === xAxisPoints.length - 1 && yearTranslate[props.lang](point)}
              </>
            )}
          </text>
        ))}
      </g>
    </svg>
  );
};
