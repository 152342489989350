import { ReactNode } from "react";

interface ICurrencyWrapper {
  currencyString: string;
  className?: string;
}

export const CurrencyWrapper = (props: ICurrencyWrapper) => {
  const nbsp = String.fromCharCode(160);
  const arr: Array<string> = props.currencyString.split(nbsp);
  const currencyString = arr.pop();
  const currencyClassname = props.className ?? "graph-content__tooltip-price-suffix";
  const wrappedCurrency: ReactNode = <tspan className={currencyClassname}>{currencyString}</tspan>;

  return (
    <>
      {arr.join(" ")}&nbsp;{wrappedCurrency}
    </>
  );
};
