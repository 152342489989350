import React, { useEffect, useState } from "react";
import { Calculator } from "./components/Calculator";
import { Graph } from "./components/Graph";
import { countProfit } from "./utils/countProfit";
import { useTranslation } from "react-i18next";
import axios from "axios";

interface IApp {
  link: string;
  lang: "en" | "cz" | "de";
}

interface IResponse {
  HomePage_PrumernyRocniVynosCZK: string;
  HomePage_PrumernyRocniVynosEUR: string;
}

const App = (props: IApp) => {
  const isCzech: boolean = props.lang === "cz";
  const [investmentAmmount, setInvestmentAmmount] = useState<number>(isCzech ? 80000 : 2000);
  const [investmentDuration, setInvestmentDuration] = useState<number>(5);
  const [apiData, setApiData] = useState<IResponse | undefined>(undefined);
  const { t } = useTranslation();
  const crownFormatter = new Intl.NumberFormat("cz-cs", {
    style: "currency",
    currency: "CZK",
    minimumFractionDigits: 0,
  });
  const euroFormatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
   useEffect(() => {
       axios("https://bondster.com/wp-json/bondsterdata/getnumbers").then((r) => {
            setApiData(r.data);
         });
       }, []);
  return (
    <div className={"row"}>
      <div className={"col-sm-16 col-md-12 col-xl-8 col-xxl-7"}>
        <div className={"position-relative me-xl-n7"}>
          <p className={"lead lead-strong mb-1"}>{t("calculator.prependTitle")}</p>
          <h2 className={"text-reset mb-5"}>{t("calculator.mainTitle")}</h2>
          <Calculator
            language={props.lang}
            isCzech={isCzech}
            formatter={isCzech ? crownFormatter : euroFormatter}
            investmentAmount={investmentAmmount}
            investmentDuration={investmentDuration}
            setInvestmentDuration={(duration) => setInvestmentDuration(duration)}
            setInvestmentAmount={(amount) => setInvestmentAmmount(amount)}
          />
          <div>
            <a className={"btn btn-secondary btn-lg"} href={props.link}>
              {t("buttons.startInvesting")}
            </a>
          </div>
        </div>
      </div>
      <div className={"col-xl-16 col-xxl-17 pt-4 pt-xl-6"}>
        <div className={"mx-n4 mx-sm-n6 mx-xl-0 overflow-auto"}>
          <div className={"graph-cover"}>
            <Graph
              yield={isCzech ? apiData?.HomePage_PrumernyRocniVynosCZK || "0" : apiData?.HomePage_PrumernyRocniVynosEUR || "0"}
              isCzech={isCzech}
              lang={props.lang}
              formatter={isCzech ? crownFormatter : euroFormatter}
              investmentDuration={investmentDuration}
              investmentAmount={investmentAmmount}
              profit={countProfit(
              investmentAmmount,
              investmentDuration,
             isCzech
                 ? parseFloat(apiData?.HomePage_PrumernyRocniVynosCZK || "0")
                  : parseFloat(apiData?.HomePage_PrumernyRocniVynosEUR || "0")
                  )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
