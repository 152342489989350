export const SvgGraphDefs = () => {
  return (
    <defs>
      <defs>
        <linearGradient spreadMethod="pad" id="linear-gradient-bezier" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{
              stopColor: "#6BABDF",
              stopOpacity: 1,
            }}
          />
          <stop
            offset="95.57%"
            style={{
              stopColor: "#6BABDF",
              stopOpacity: 0,
            }}
          />
        </linearGradient>
        <linearGradient spreadMethod="pad" id="linear-gradient-stroke" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{
              stopColor: "#fff",
              stopOpacity: 0,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: "#fff",
              stopOpacity: 0.16,
            }}
          />
        </linearGradient>
        <linearGradient spreadMethod="pad" id="linear-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{
              stopColor: "#6BABDF",
              stopOpacity: 0,
            }}
          />
          <stop
            offset="55%"
            style={{
              stopColor: "#6BABDF",
              stopOpacity: 0.9,
            }}
          />
          <stop
            offset="90%"
            style={{
              stopColor: "#6BABDF",
              stopOpacity: 0.1,
            }}
          />
        </linearGradient>
        <linearGradient spreadMethod="pad" id="linear-gradient-background" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop
            offset="0%"
            style={{
              stopColor: "#284268",
              stopOpacity: 0,
            }}
          />
          <stop
            offset="100%"
            style={{
              stopColor: "#284268",
              stopOpacity: 0.6,
            }}
          />
        </linearGradient>
      </defs>
    </defs>
  );
};
