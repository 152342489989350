import { RangeSlider } from "./RangeSlider";
import { yearTranslate } from "../utils/yearTranslate";
import {useTranslation} from "react-i18next";

interface ICalculator {
  investmentAmount: number;
  setInvestmentAmount: (amount: number) => void;
  investmentDuration: number;
  setInvestmentDuration: (duration: number) => void;
  formatter: Intl.NumberFormat;
  isCzech: boolean;
  language: "cz" | "en" | "de";
}

export const Calculator = (props: ICalculator) => {
  const { t } = useTranslation();
  const getLanguage = (): "cz" | "en" | "de" => {
    const { language } = props;
    const allowedLanguages = ["cz", "de", "en"];
    return allowedLanguages.indexOf(language) > -1 ? language : "en";
  };
  return (
    <div>
      <RangeSlider
        title={t("rangebar.investAmount")}
        step={props.isCzech ? 1000 : 50}
        max={props.isCzech ? 200000 : 10000}
        min={props.isCzech ? 10000 : 500}
        value={props.investmentAmount}
        setValue={(amount) => props.setInvestmentAmount(amount)}
        suffix={""}
        formattedValue={props.formatter.format(props.investmentAmount)}
      />
      <RangeSlider
        title={t("rangebar.investDuration")}
        step={1}
        max={20}
        min={1}
        value={props.investmentDuration}
        setValue={(amount) => props.setInvestmentDuration(amount)}
        suffix={` ${yearTranslate[getLanguage()](props.investmentDuration)}`}
      />
    </div>
  );
};
