import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./style.scss";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import czLang from "./translations/cz.json";
import enLang from "./translations/en.json";
import deLang from "./translations/de.json";

const el = document.getElementById("investment-calc");
const buttonUrl = el?.getAttribute("data-link");
const lang = (el?.getAttribute("data-lang") || "cz") as "cz" | "de" | "en";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      cz: {
        translation: czLang,
      },
      en: {
        translation: enLang,
      },
      de: {
        translation: deLang,
      },
    },
    lng: lang,
    fallbackLng: "en",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => {});

ReactDOM.render(
  <React.StrictMode>
    <App link={buttonUrl || ""} lang={lang} />
  </React.StrictMode>,
  el
);
