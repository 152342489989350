interface IYearTranslate {
  cz: (yearNumber: number) => string;
  en: (yearNumber: number) => string;
  de: (yearNumber: number) => string;
}

export const yearTranslate: IYearTranslate = {
  cz: (yearNumber) => {
    switch (yearNumber) {
      case 1:
        return "rok";
      case 2:
      case 3:
      case 4:
        return "roky";
      default:
        return "let";
    }
  },
  en: (yearNumber) => {
    switch (yearNumber) {
      case 1:
        return "year";
      default:
        return "years";
    }
  },
  de: (yearNumber) => {
    switch (yearNumber) {
      case 1:
        return "Jahr";
      case 2:
      case 3:
      case 4:
        return "Jahre";
      default:
        return "Jahre";
    }
  },
};
