import { CurrencyWrapper } from "../utils/currencyWrapper";
interface IRangeSlider {
  title: string;
  suffix: string;
  min: number;
  max: number;
  step: number;
  value: number;
  setValue: (value: number) => void;
  formattedValue?: string;
}

export const RangeSlider = (props: IRangeSlider) => {
  const percentage = (100 / (props.max - props.min)) * (props.value - props.min);
  return (
    <div className={"investment__range"}>
      <div className={"investment__range-text"}>
        <div className={"investment__range-text--title"}>{props.title}</div>
        <div className={"investment__range-text--value"}>
            {props.formattedValue ? <CurrencyWrapper currencyString={props.formattedValue} className={"investment__range-text--value-suffix"} /> : props.value}
          <span className={"investment__range-text--value-suffix"}>{props.suffix}</span>
        </div>
      </div>
      <input
        className={"investment__range-slider"}
        type={"range"}
        min={props.min}
        max={props.max}
        step={props.step}
        value={props.value}
        onChange={(e) => props.setValue(parseInt(e.target.value))}
        style={{
          background: `linear-gradient(to right, #ecaa14, #ecaa14 ${percentage}%, #fff ${percentage}%, #fff)`,
        }}
      />
    </div>
  );
};
